<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-card-title class="error">
            <span class="white--text">500 Internal Server Error</span>
          </v-card-title>
          <v-card-text>
            <div class="text-center py-8">
              <h1>Oops, Something Went Wrong</h1>
              <p class="mt-2">There was an internal server error while processing your request.</p>
              <v-btn color="error" to="/">Go Back to Home</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ServerErrorPage',
}
</script>

<style scoped>
.error {
  background-color: #f44336;
}
</style>
